import { graphql } from "gatsby"
import React from "react"
import { ArticleFeed, ArticleFeedItem } from "../../components/ghost/articlefeed"
import Layout from "../../components/layout"
import { Section } from "../../components/section"
import SEO from "../../components/SEO/SEO"
import { enhanceArticle } from "../../pages/help/cullai"

const Category = ({ data, location, pageContext }) => {
  const { root } = data
  let articles = root.articles
  const { category, source } = pageContext

  if (source == "blog") {
    articles = articles.sort((a,b) => new Date(b.frontmatter.date) - new Date(a.frontmatter.date) )
  }

  return (
    <Layout>
      <SEO
        title={category}
        pathname={location.pathname}
        desc={`Articles in ${category} series`}
        type="Series"
        series={{ name: category }}
      />
      <Section nogrid noborder className="related-posts container medium" gridClassName="max-w-3xl mx-auto">
        <h1 className="related-title text-2xl font-bold text-gray-800 mb-4">{category}</h1>
        <ArticleFeed>
          {articles.map(article => {
            enhanceArticle(article)
            return (
              <ArticleFeedItem key={article.id} article={article}></ArticleFeedItem>
            )
          })}
        </ArticleFeed>
      </Section>
    </Layout>
  )
}

export default Category

export const categoryQuery = graphql`
query CategoryQuery($category: String!) {
  root: allMdx(
    filter: {frontmatter: {category: {eq: $category}}}
    sort: {fields: frontmatter___sn, order: ASC}
  ) {
    articles: nodes {
      id
      excerpt(pruneLength: 128)
      timeToRead
      frontmatter {
        product
        category
        title
        date(formatString: "MMM DD, YYYY")
      }
      fields {
        source
      }
    }
  }
}
`